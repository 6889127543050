<template>
        <div class="relative inline-block w-64">
          <button @click="toggleDropdown" class="block w-full bg-white-main border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white-main">
            <div class="flex items-center">
              <img :src="selectedGameLogo" :alt="selectedGameName" class="w-5 h-5 inline-block mr-2" />
              <span>{{ selectedGameName }}</span>
            </div>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z"/></svg>
            </div>
          </button>
          
          <div v-if="dropdownOpen" class="absolute mt-1 w-full rounded-md shadow-lg bg-white-main z-10">
            <ul class="py-1">
              <li @click="selectGame('leagueoflegends')" class="flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <img :src="require('@/assets/img/league-logo.png')" alt="League of Legends" class="w-5 h-5 inline-block mr-2" />
                League of Legends
              </li>
              <li @click="selectGame('valorant')" class="flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <img :src="require('@/assets/img/valorant-logo.png')" alt="Valorant" class="w-5 h-5 inline-block mr-2" />
                Valorant
              </li>
            </ul>
          </div>
        </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dropdownOpen: false,
        selectedGame: 'leagueoflegends',
      };
    },
    computed: {
      selectedGameName() {
        return this.selectedGame === 'leagueoflegends' ? 'League of Legends' : 'Valorant';
      },
      selectedGameLogo() {
        return this.selectedGame === 'leagueoflegends'
          ? require('@/assets/img/league-logo.png')
          : require('@/assets/img/valorant-logo.png');
      }
    },
    methods: {
      toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
      },
      selectGame(game) {
        this.selectedGame = game;
        this.dropdownOpen = false;
        this.$emit('game-selected', this.selectedGame);
      }
    }
  };
  </script>
  