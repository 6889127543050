<template>
  <section id="home" class="h-[100vh]">
    <div
      class="w-[427px] h-[422px] absolute right-[-184px] top-[14px] bg-x-image bg-cover bg-no-repeat z-10"
    ></div>
    <div
      class="w-[427px] h-[422px] rotate-[-41deg] absolute right-[-85.118px] bottom-[0.375px] bg-x-image bg-cover bg-no-repeat z-10"
    ></div>
    <div
      class="w-[1330px] h-[1507.827px] absolute left-[-759px] bottom-[-549.827px] bg-pingo-image bg-cover bg-no-repeat z-10"
    ></div>
    <div
      class="flex flex-col items-center justify-center bg-white-main h-[85vh] gap-4"
    >
      <a
        href="https://api.whatsapp.com/send?phone=5511969727540&text=Olá,%20gostaria%20de%20contratar%20um%20serviço."
        target="_blank"
        class="fixed z-20 right-20 bottom-40 w-[70px] h-[70px] transition-transform duration-300 ease-in-out transform hover:translate-y-[-10px]"
      >
        <img
          src="../assets/img/social/zap.png"
          alt="WhatsApp"
          class="w-full h-full object-cover"
        />
      </a>

      <video
        autoplay
        muted
        loop
        class="absolute top-[0px] left-[0px] w-full h-full object-cover z-0 overflow-hidden"
      >
        <source src="../assets/video/DRAGAO_HOME_WHT.mp4" type="video/mp4" />
        Seu navegador não suporta vídeos em HTML5.
      </video>
      <h1 class="font-extrabold text-5xl z-10">
        Alcance o ELO que você merece!
      </h1>
      <h2 class="text-xl font-medium z-10" w>
        Nós ajudamos você a subir de ELO, conquistar recompensas e deixar os
        jogadores tóxicos para trás.
      </h2>
      <Button
        text="Contrate Agora"
        bgColor="bg-black-main"
        @click="goToService('services')"
      />
    </div>
  </section>
  <ServicesView />
  <ReviewsView />
  <BenefitsView />
</template>

<script>
import Button from "@/components/Button.vue";
import ServicesView from "./ServicesView.vue";
import ReviewsView from "./ReviewsView.vue";
import BenefitsView from "./BenefitsView.vue";

export default {
  name: "HomeView",
  components: {
    Button,
    ServicesView,
    ReviewsView,
    BenefitsView,
  },
  methods: {
    goToService(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="css"></style>
