<template>
  <div
    class="bg-black-main w-[332px] h-[191px] rounded-[20px] drop-shadow-md flex flex-col justify-between p-4 text-left"
  >
    <div class="flex items-center justify-between">
      <img
        src="../assets/img/transferir.jpeg"
        class="w-[49px] h-[49px] rounded-full object-cover"
        alt="Imagem circular"
      />
      <div class="ml-3">
        <h1 class="text-[#8E8E8E] text-[12px] font-medium">
          Pedido #{{ orderNumber }}
        </h1>
        <h2 class="text-white text-[12px] font-medium">Há 1 hora atrás</h2>
      </div>
      <div>
        <p>⭐⭐⭐⭐⭐</p>
      </div>
    </div>

    <div>
      <h2 class="text-white text-[12px] font-medium">
        {{ titleService }}
      </h2>
    </div>

    <div>
      <h1 class="text-[#8E8E8E] text-[12px] font-medium">
        {{ descService }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleService: {
      type: String,
      required: true,
    },
    descService: {
      type: String,
      required: true,
    },
    orderNumber: {
      type: Number,
      required: true,
    },
    reviewNumber: {
        type: Number,
        required: true
    }
  },
};
</script>
