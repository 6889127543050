<template>
  <div
    class="gap-[30px] w-[380px] h-[590px] rounded-[30px] flex flex-col items-center justify-center"
    :class="selectEloBg"
  >
    <img class="w-[200px] h-[200px]" :src="selectImg" />
    <h1
      class="text-white text-[50px] font-bold"
      v-if="selectedElo !== 'Grão Mestre' && selectedElo !== 'Mestre'"
    >
      {{ selectedElo }} {{ divisionNumber }}
    </h1>

    <h1 class="text-white text-[50px] font-bold" v-else>
      {{ selectedElo }}
    </h1>

    <div class="w-[100%] items-center justify-center flex flex-col">
      <h1 class="text-white text-base font-semibold">
        Selecione seu ultimo elo
      </h1>
      <Button
        class="w-[330px] h-[50px]"
        :text="selectedElo"
        :bgColor="selectButton"
        :onClick="openModal"
      />
    </div>

    <div
      class="w-[100%] items-center justify-center flex flex-col"
      v-if="selectedElo !== 'Grão Mestre' && selectedElo !== 'Mestre'"
    >
      <h1 class="text-white text-base font-semibold">
        Selecione o modo do serviço
      </h1>
      <select
        v-model="selectedDivision"
        class="w-[330px] h-[50px] text-white text-center font-medium text-[16px] rounded-[12px]"
        :class="selectButton"
        @change="refreshData()"
      >
        <option value="Solo">Solo</option>
        <option value="Duo">Duo</option>
      </select>
    </div>

    <div
      v-if="showModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div
        class="bg-white w-[80vw] h-[300px] rounded-[20px] p-[20px] flex flex-col items-center justify-between"
      >
        <div class="w-[100%] flex justify-between">
          <h2 class="text-black text-[24px] font-semibold mt-[10px]">
            Escolha seu Elo
          </h2>
          <button
            @click="closeModal"
            class="self-end text-black text-[20px] font-bold"
          >
            X
          </button>
        </div>

        <div class="flex">
          <div
            class="cursor-pointer hover:bg-red-main hover:text-white rounded-[20px]"
            v-for="(select, index) in elos"
            :key="index"
            @click="selectElo(select)"
          >
            <img class="h-[200px] w-[200px]" :src="select.img" />
            <h1>{{ select.elo }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import ferro from "../assets/img/eloValorant/iron.png";
import bronze from "../assets/img/eloValorant/bronze.png";
import prata from "../assets/img/eloValorant/silver.png";
import ouro from "../assets/img/eloValorant/gold.png";
import platina from "../assets/img/eloValorant/platinum.png";
import diamante from "../assets/img/eloValorant/diamando.png";
import ascendente from "../assets/img/eloValorant/ascendant.png";

export default {
  components: {
    Button,
  },
  data() {
    return {
      showDropdown: false,
      selectedDivision: "Solo",
      showModal: false,
      selectedElo: "Bronze",
      selectImg: bronze,
      selectEloBg: "bg-bronze-500",
      selectButton: "bg-bronze-900",
      elos: [
        {
          elo: "Ferro",
          img: ferro,
          bgColor: "bg-ferro-500",
          buttonColor: "bg-ferro-900",
        },
        {
          elo: "Bronze",
          img: bronze,
          bgColor: "bg-bronze-500",
          buttonColor: "bg-bronze-900",
        },
        {
          elo: "Prata",
          img: prata,
          bgColor: "bg-slate-500",
          buttonColor: "bg-slate-900",
        },
        {
          elo: "Ouro",
          img: ouro,
          bgColor: "bg-gold-500",
          buttonColor: "bg-gold-900",
        },
        {
          elo: "Platina",
          img: platina,
          bgColor: "bg-cyan-500",
          buttonColor: "bg-cyan-900",
        },
        {
          elo: "Diamante",
          img: diamante,
          bgColor: "bg-purple-500",
          buttonColor: "bg-purple-900",
        },
        {
          elo: "Ascendente",
          img: ascendente,
          bgColor: "bg-emerald-500",
          buttonColor: "bg-emerald-900",
        },
      ],
    };
  },
  computed: {
    divisionNumber() {
      return this.selectedDivision.split(" ")[1];
    },
  },
  methods: {
    goto() {
      console.log("aqui");
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    selectElo(select) {
      this.selectedElo = select.elo;
      this.selectImg = select.img;
      this.selectEloBg = select.bgColor;
      this.selectButton = select.buttonColor;
      this.showModal = false;
      this.refreshData()
    },
    refreshData() {
      this.$emit("updateData", this.selectedElo, this.selectedDivision);
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style></style>
