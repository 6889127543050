<template>
  <section class="h-[91vh] w-[100vw] flex justify-center items-center">
    <div
      class="z-0 text-white-main w-[110%] h-[100%] absolute bg-white-profile bg-cover bg-no-repeat"
    ></div>
    <div class="flex flex-col items-start px-52 pt-20 z-10">
      <!-- Profile Section -->
      <div class="flex items-center gap-4">
        <div
          class="w-24 h-24 rounded-full flex items-center justify-center bg-black-main border border-gray-300"
        >
          <span class="text-white text-4xl font-bold">{{ userName ? userName.charAt(0).toUpperCase() : "" }} </span>
        </div >          
        <div>
          <h1 class="text-lg font-medium text-left">{{userName}}</h1>
          <p class="text-gray-500 text-sm">Membro desde: {{ formatDate(registerDate) }}</p>
        </div>
      </div>

      <!-- Edit Profile Title -->
      <h2 class="mt-20 text-xl font-bold">Editar Perfil</h2>

      <!-- Edit Profile Fields -->
      <div class="flex flex-col">
        <div class="mt-20 flex flex-row gap-10">
          <div class="flex flex-col gap-2">
            <label
              for="username"
              class="text-black text-[15px] font-medium text-left"
            >
              Nome de Usuário
            </label>
            <div
              class="flex items-center bg-black-main rounded-[20px] p-[17px_9px_17px_20px] gap-4"
            >
              <img
                src="@/assets/svg/person_svg.svg"
                alt="User Icon"
                class="w-5 h-5 text-gray-500"
              />
              <input
                type="text"
                id="username"
                placeholder="Nome de usuário"
                class="flex-1 outline-none text-white bg-black-main"
                v-model="currentName"
              />
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <label
              for="email"
              class="text-black text-[15px] font-medium text-left"
            >
              E-mail
            </label>
            <div
              class="flex items-center bg-black-main rounded-[20px] p-[17px_9px_17px_20px] gap-4"
            >
              <img
                src="@/assets/svg/email_svg.svg"
                alt="User Icon"
                class="w-5 h-5 text-gray-500"
              />
              <input
                type="email"
                id="email"
                placeholder="exemplo@email.com"
                class="flex-1 outline-none text-white bg-black-main"
                v-model="email"
              />
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <label
              for="phone"
              class="text-black text-[15px] font-medium text-left"
            >
              Telefone
            </label>
            <div
              class="flex items-center bg-black-main rounded-[20px] p-[17px_9px_17px_20px] gap-4"
            >
              <img
                src="@/assets/svg/phone_svg.svg"
                alt="User Icon"
                class="w-5 h-5 text-gray-500"
              />
              <input
                type="tel"
                id="phone"
                placeholder="(99) 99999-9999"
                class="flex-1 outline-none text-white bg-black-main"
                v-model="telefone"
              />
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <label
              for="discord"
              class="text-black text-[15px] font-medium text-left"
            >
              Discord
            </label>
            <div
              class="flex items-center bg-black-main rounded-[20px] p-[17px_9px_17px_20px] gap-4"
            >
              <img
                src="@/assets/svg/discord_svg.svg"
                alt="User Icon"
                class="w-5 h-5 text-gray-500"
              />
              <input
                type="text"
                id="discord"
                placeholder="Usuário#0000"
                class="flex-1 outline-none text-white bg-black-main"
                v-model="discord"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-28 w-full">
          <button
            class="bg-red-main text-white px-4 py-2 rounded-lg hover:bg-red-500"
            @click="editUser"
          >
            Salvar Alterações
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '../service/api';

export default {
  name: "Profile",
  data() {
    return {
      token: localStorage.getItem('token'),
      id: localStorage.getItem('id'),
      img: '',
      userName: '',
      currentName: '',
      email: '',
      telefone: '',
      discord: '',
      registerDate: '',
    };
  },
  methods: {
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);

          this.currentName = response.data.username;
          this.userName = response.data.username;
          this.img = response.data.foto;
          this.registerDate = response.data.data_registro;
          this.email = response.data.email;
          this.telefone = response.data.telefone;
          this.discord = response.data.discord;

          console.log(this.userName)
        } catch (error) {
          console.error(error);
        }
      }
    },
    async editUser(){
        try {
          await api.put(`user/edit/${this.id}`, {
            username: this.currentName,
            email: this.email,
            telefone: this.telefone,
            discord: this.discord
          });
          this.getUser()

          location.reload();
        } catch (error) {
          console.error(error);
        }
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, '0'); 
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear(); 

      return `${day}/${month}/${year}`; 
    }
    
  },
  mounted(){
    this.getUser()
  }
};
</script>

<style scoped>
input::placeholder {
  color: #888;
}
</style>
