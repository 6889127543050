<template>
  <section class="h-[120vh] flex gap-10 p-[90px] px-[200px] bg-black-main">
    <div class="flex flex-col gap-20">
      <div class="flex gap-10">
        <BoostCardDe @updateData="eloDataDe" />
        <BoostCardLolAte @updateData="eloDataAte" />
      </div>
      <div class="flex flex-col justify-center items-start gap-10 w-[800px]">
        <div class="w-[800px] h-[0px] border border-slate-400"></div>

        <h1 class="text-white font-medium">Como funciona o Elojob?</h1>

        <h2 class="text-white text-sm font-medium text-start">
          O serviço de Elojob em partidas Flex funciona de maneira semelhante ao
          Elojob tradicional, mas focado no modo de filas flexíveis. Nesse
          formato, o booster joga na conta do cliente, com o objetivo de subir o
          elo nas filas Flex. O cliente escolhe seu elo atual e o desejado, e o
          booster realiza o serviço, garantindo a segurança da conta e o
          cumprimento das metas estabelecidas. Esse serviço é ideal para quem
          deseja melhorar o elo em Flex, com preços que variam conforme o
          objetivo traçado.
        </h2>
      </div>
    </div>

    <div class="flex flex-col justify-start gap-[30px] w-[500px]">
      <h1 class="text-start text-white text-3xl font-semibold uppercase">
        {{ eloDe }} {{ divisionNumberDe }} AO {{ eloAte }}
        {{ divisionNumberAte }} FLEX
      </h1>
      <div
        class="flex justify-start items-center gap-[30px]"
        v-if="servicePrince > 0"
      >
        <h2
          class="w-[90px] h-[40px] p-[10px] text-center bg-white-main rounded-[30px] font-semibold text-base"
        >
          10% OFF
        </h2>
        <h2 class="font-semibold text-red-700 line-through">
          DE: R$ {{ (servicePrince * 1.1).toFixed(2) }}
        </h2>
        <h2 class="font-semibold text-white">POR: R$ {{ servicePrince }}</h2>
      </div>
      <div v-else>
        <h1 class="text-start text-red-700 text-xl font-semibold uppercase">
          É necessario escolher um elo incial menor que o elo final
        </h1>
      </div>

      <h3 class="text-start text-white text-sm">Personalizar seu pedido</h3>
      <div class="grid grid-rows-3 grid-flow-col gap-4 w-[500px]">
        <Custom text="Lane" price="FREE" />
        <Custom text="Campeões" price="FREE" />
        <Custom text="Stream Discord" price="FREE" />
        <Custom text="Chat Off" price="FREE" />
        <Custom text="Horários" price="+10%" />
        <Custom text="Entrega Rápida" price="+15%" />
      </div>

      <div class="flex flex-col gap-3">
        <h3 class="text-start text-slate-300 text-xs">
          Inicio em até 24 horas
        </h3>
        <h3 class="text-start text-white text-sm">
          Pedindo finalizado em até 30 dias
        </h3>
      </div>

      <Button
        :text="`Contratar (R$ ${servicePrince})`"
        bgColor="bg-red-main"
        @Click="payment()"
        class="w-[500px] h-[60px] p-[10px]"
        :disableButton="servicePrince <= 0"
      />
    </div>
  </section>
  <div class="h-[0.5px] border border-slate-400"></div>
</template>

<script>
import Custom from "../../components/Custom.vue";
import BoostCardDe from "../../components/BoostCardLolDe.vue";
import BoostCardLolAte from "../../components/BoostCardLolAte.vue";
import Button from "@/components/Button.vue";
import api from "../../service/api";

export default {
  components: {
    BoostCardDe,
    BoostCardLolAte,
    Custom,
    Button,
  },
  computed: {
    divisionNumberDe() {
      return this.divisionDe.split(" ")[1];
    },
    divisionNumberAte() {
      return this.divisionAte.split(" ")[1];
    },
    princeDescont(price) {
      return price * 1.1;
    },
    servicePrince() {
      const elos = [
        "Ferro",
        "Bronze",
        "Prata",
        "Ouro",
        "Platina",
        "Esmeralda",
        "Diamante",
        "Mestre",
        "Grão Mestre",
        "Desafiante",
      ];

      const prices = [
        6, 6, 6, 6, 6, 7, 7, 7, 7, 9, 9, 9, 9, 15, 15, 15, 15, 20, 20, 20, 20,
        39, 39, 39, 39, 60, 60, 60, 80, 350, 500,
      ];

      const initialEloIndex = elos.indexOf(this.eloDe);
      const finalEloIndex = elos.indexOf(this.eloAte);

      let total = 0;
      let startIndex =
        initialEloIndex * 4 +
        Math.max(0, 4 - this.romanToNumber(this.divisionNumberDe));
      let endIndex =
        finalEloIndex * 4 + (4 - this.romanToNumber(this.divisionNumberAte));

      if (this.eloAte === "Mestre") {
        endIndex = prices.length - 3;
      } else if (this.eloAte === "Grão Mestre") {
        endIndex = prices.length - 2;
      } else if (this.eloAte === "Desafiante") {
        endIndex = prices.length - 1;
      }

      if (this.eloDe === "Mestre") {
        startIndex = prices.length - 3;
      } else if (this.eloDe === "Grão Mestre") {
        startIndex = prices.length - 2;
      }

      for (let i = startIndex + 1; i <= endIndex; i++) {
        total += prices[i];
      }

      return total.toFixed(2);
    },
  },
  data() {
    return {
      userName: "",
      email: "",
      eloDe: "",
      divisionDe: "",
      eloAte: "",
      divisionAte: "",
    };
  },
  async mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);
          this.userName = response.data.username;
          this.email = response.data.email;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async payment() {
      try {
        if (!this.id) {
          localStorage.setItem('redirectTo', window.location.href); 
          window.location.href = "/login";
          return;
        }
        
        const response = await api.post("/mercadopago/payment", {
          jogo: "League of legends",
          price: parseFloat(this.servicePrince),
          description: ` ${this.eloDe} ${this.divisionNumberDe} AO ${this.eloAte} ${this.divisionNumberAte} FLEX`,
          payer: {
            email: this.email,
            name: this.userName,
          },
        });

        window.location.href = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    eloDataDe(elo, division) {
      this.eloDe = elo;
      this.divisionDe = division;
    },
    eloDataAte(elo, division) {
      this.eloAte = elo;
      this.divisionAte = division;
    },
    romanToNumber(roman) {
      const romanNumeralMap = {
        I: 1,
        II: 2,
        III: 3,
        IV: 4,
      };

      return romanNumeralMap[roman] || null;
    },
  },
};
</script>
