<template>
  <section class="flex flex-col h-[42vh] bg-black-main">
    <div class="flex w-[100%]">
      <div class="flex flex-col w-[50%] p-10 gap-5 text-left">
        <img
          src="../assets/img/logo-main-job.png"
          class="w-[180px] h-[65px] object-cover"
          alt="Dinasty"
        />
        <h2>
          League of Legends e Valorant são marcas registradas da Riot Games,
          Inc. Não somos de forma alguma afiliados, associados ou endossados
          pela Riot Games, Inc.
        </h2>
      </div>
      <div class="w-[50%] flex gap-20 p-10 text-left">
        <div class="flex flex-col gap-2">
          <h1 class="mb-5">Páginas</h1>
          <a href="#home"> Home </a>
          <a href="#services"> Serviços </a>
          <a href="#benefits"> Benefícios </a>
          <a href="#reviews"> Avaliações </a>
        </div>
        <div class="flex flex-col gap-2">
          <h1 class="mb-5">Contato</h1>
          <a href="https://api.whatsapp.com/send?phone=5511969727540&text=Olá,%20gostaria%20de%20contratar%20um%20serviço." target="_blank">
            Whatsapp
           </a>
          <a href="mailto:dinastyelojob@gmail.com" target="_blank">
            E-mail
          </a>
        </div>
      </div>
    </div>
    <div class="w-[100%] flex justify-between">
      <div class="flex gap-4 p-10">
        <h2>Termos de uso</h2>
        <h2>Política de Privacidade</h2>
      </div>

      <div class="flex p-10">
        <a href="https://discord.gg/2B6vnFTh" target="_blank">
          <img
            src="../assets/img/social/discord.webp"
            class="w-[50px] h-[50px] object-cover m-[7px]"
            alt="discord"
          />
        </a>

        <a href="https://www.instagram.com/dinastyjob" target="_blank">
        <img
          src="../assets/img/social/insta.svg"
          class="w-[83px] h-[53px] object-cover"
          alt="instagram"
        />
      </a>

      <a href="https://www.facebook.com/profile.php?id=61559397791481" target="_blank">
        <img
          src="../assets/img/social/facebook.svg"
          class="w-[63px] h-[40px] object-cover m-[7px]"
          alt="facebook"
        />
      </a>


      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
h1 {
  @apply text-white font-extrabold text-xl;
}

h2 {
  @apply text-white text-sm font-medium leading-normal;
}

a {
  @apply text-white text-sm font-medium leading-normal;

  &:hover {
    @apply text-red-main;
  }
}
</style>
