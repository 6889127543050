<template>
  <div
    class="bg-black-main flex w-[285px] h-[400px] flex-col justify-center items-center gap-[21px] rounded-[20px]"
  >
    <img class="w-[75px] h-[79.44px]" src="../assets/img/logo-notext.png" />
    <img class="min-w-[161.57px] max-w-[272.435px] h-[26px]" :src="imgText" />
    <div class="text-white text-base font-semibold font-['Montserrat']">
      {{ tittleText }}
    </div>
    <div
      class="w-[230px] h-[79px] text-white text-xs font-medium font-['Montserrat']"
    >
      {{ descriptionText }}
    </div>
    <div class="#ea234f">
      <Button text="Contratar" bgColor="bg-red-main" @click="goToService"/>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";

export default {
  props: {
    tittleText: {
      type: String,
      required: true,
    },
    descriptionText: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    imgText: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },
  components: {
    Button,
  },
  methods: {
    goToService() {
      this.$router.push(this.route);
      console.log('aqui')
    },
  },
};
</script>
