<template>
    <div class="relative inline-block">
      <div @click="toggleDropdown" class="cursor-pointer">
        <div class="w-10 h-10 rounded-full flex items-center justify-center bg-white border border-gray-300">
          <img v-if="img" :src="img" alt="User Image" class="w-full h-full rounded-full object-cover" />
          <span v-else class="text-gray-500 text-xl font-bold">{{ username ? username.charAt(0).toUpperCase() : '' }}</span>
        </div>
      </div>
  
      <div v-if="isOpen" class="dropdown-menu absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
        <ul class="py-2">
          <li @click="navigateTo('/pedido')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Pedido</li>
          <li @click="navigateTo('/profile')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Perfil</li>
          <li v-if="role === 'Admin'" @click="navigateTo('/admin')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Adiministrativo</li>
          <li @click="logout" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">Sair</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      img: {
        type: String,
        default: '',
      },
      username: {
        type: String,
        default: '',
      },
      role: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      navigateTo(route) {
        this.$router.push(route);
        this.isOpen = false;
      },
      logout() {
        localStorage.clear();
        location.reload();
      }
    },
  };
  </script>
  
  <style scoped>
  .dropdown-menu {
    position: absolute;
    right: 0; 
    top: 100%; 
    z-index: 10; 
  }
  

  </style>
  