<template>
  <section
    class="h-[91vh] w-[100vw] bg-red-main flex justify-center items-center"
  >
    <div
      class="z-0 text-white-main w-[110%] h-[100%] absolute bg-rabisco-adm-image bg-cover bg-no-repeat"
    ></div>
    <div class="table-container bg-white rounded-[10px] z-10">
      <table class="w-full border-collapse">
        <thead>
          <tr>
            <th class="border p-2 min-w-[120px]">Pedido</th>
            <th class="border p-2 min-w-[120px]">Jogo</th>
            <th class="border p-2 min-w-[120px]">Nick</th>
            <th class="border p-2 min-w-[120px]">User</th>
            <th class="border p-2 min-w-[120px]">Telefone</th>
            <th class="border p-2 min-w-[120px]">Senha</th>
            <th class="border p-2 min-w-[120px]">Discord</th>
            <th class="border p-2 min-w-[120px]">Serviço</th>
            <th class="border p-2 min-w-[120px]">Booster</th>
            <th class="border p-2 min-w-[120px]">Pagamento</th>
            <th class="border p-2 min-w-[120px]">Valor</th>
            <th class="border p-2 min-w-[120px]">Status</th>
            <th class="border p-2 min-w-[120px]">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dataOrder, index) in paginatedOrders" :key="dataOrder.id">
            <td class="border p-2 min-w-[120px]">#{{ dataOrder.id }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.jogo }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.nick }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.conta }}</td>
            <td
              class="border p-2 min-w-[120px] cursor-pointer"
              @click="togglePassword(dataOrder)"
            >
              {{ dataOrder.showPassword ? dataOrder.senha : "****" }}
            </td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.usuario.telefone }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.usuario.discord }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.pedido }}</td>
            <td class="border p-2 min-w-[120px]">{{ dataOrder.booster }}</td>
            <td class="border p-2 min-w-[120px]">{{ getPaymentTypeText(dataOrder.paymentType) }}</td>
            <td class="border p-2 min-w-[120px]">R$:{{ parseFloat(dataOrder.valor).toFixed(2) }}</td>
            <td class="border p-2 min-w-[120px]">
              <span
                :class="getStatusClass(dataOrder.status)"
                class="p-2 rounded-full text-white"
              >
                {{ getStatusText(dataOrder.status) }}
              </span>
            </td>
            <td
              class="border p-2 min-w-[120px] flex justify-center items-center space-x-2"
            >
              <button
                @click="editOrder(dataOrder)"
                class="bg-blue-500 text-white p-1 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 3a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V4a1 1 0 00-1-1H11zM7 14a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1H7zM3 10a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1H3z"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr v-for="i in 15 - paginatedOrders.length" :key="'empty-' + i">
            <td colspan="11" class="border p-2 min-w-[120px]">&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-between p-2">
        <button
          @click="previousPage"
          :disabled="currentPage === 1"
          class="bg-gray-300 p-2 rounded"
        >
          Anterior
        </button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="bg-gray-300 p-2 rounded"
        >
          Próxima
        </button>
      </div>
    </div>

    <OrderModal
      :isVisible="isEditing"
      :currentOrder="currentOrder"
      @update:visible="closeModal"
      @saveOrder="saveOrder"
    />
  </section>
</template>

<script>
import OrderModal from "../components/adminComponents/orderModal.vue";
import api from '../service/api';
export default {
  components: {
    OrderModal,
  },
  data() {
    return {
      id: localStorage.getItem('id'),
      role: '',
      dataOrder: '',
      currentPage: 1,
      itemsPerPage: 15,
      isEditing: false,
      currentOrder: {},
    };
  },
  computed: {
    paginatedOrders() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.dataOrder.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.dataOrder.length / this.itemsPerPage);
    },
  },
  async mounted() {
    await this.getUser();

    if (this.role !== 'Admin') {
      this.$router.push("/home");
    } else {
      this.getAllOrders();
    }
  },
  methods: {
    getPaymentTypeText(paymentType) {
    const paymentTypeMapping = {
      ticket: "Boleto",
      bank_transfer: "Pix",
      credit_card: "Cartão de crédito",
      debit_card: "Cartão de débito",
      prepaid_card: "Cartão pré-pago",
      digital_wallet: "Paypal",
    };
    return paymentTypeMapping[paymentType] || "Desconhecido";
  },
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);
          this.role = response.data.cargo;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getAllOrders() {
      if (this.role === 'Admin') {
        try {
          const response = await api.get(`order/list/${this.id}`);
          this.dataOrder = response.data;
          console.log(response.data)
        } catch (error) {
          console.error(error);
        }
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    editOrder(order) {
      this.isEditing = true;
      this.currentOrder = { ...order };
    },
    closeModal() {
      this.isEditing = false;
    },
    async editOrderPut(order){
      try{
        await api.put(`order/${order._id}`,{
          nick: order.nick,
          conta: order.conta,
          senha: order.senha,
          status: order.status,
          booster: order.booster,
        })


      }catch (error){

      }
    },
    saveOrder() {
      const index = this.dataOrder.findIndex(
        (order) => order.id === this.currentOrder.id
      );
      if (index !== -1) {
        this.dataOrder.splice(index, 1, this.currentOrder);
      }

      this.editOrderPut(this.currentOrder)

      this.closeModal();
    },
    removeOrder(id) {
      this.dataOrder = this.dataOrder.filter((order) => order.id !== id);
    },
    togglePassword(dataOrder) {
      dataOrder.showPassword = !dataOrder.showPassword;
    },
    // Função para retornar o texto do status
    getStatusText(status) {
      const statusMapping = {
        pending: "Pendente",
        approved: "Aprovado",
        rejected: "Rejeitado",
        null: "Desconhecido",
        andamento: "Em andamento",
        concluido: "Concluído",
      };
      return statusMapping[status] || "Desconhecido";
    },
    // Função para retornar a classe da cor baseada no status
    getStatusClass(status) {
      return {
        "bg-orange-500": status === "pending", // Laranja
        "bg-green-500": status === "approved", // Verde
        "bg-red-500": status === "rejected", // Vermelho
        "bg-gray-500": status === "null", // Cinza
        "bg-yellow-500": status === "andamento", // Amarelo
        "bg-blue-500": status === "concluido", // Azul
      };
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 95%;
  max-height: 85%;
  overflow: auto;
}
</style>
