<template>
  <div
    class="relative overflow-visible drop-shadow bg-black-main p-5 flex items-center justify-center gap-[20%] w-[100vw] z-50"
  >
    <div class="background-image"></div>
    <div class="z-10">
      <img
        src="../assets/img/logo-main.png"
        alt=""
        class="w-[170px] h-[50px] cursor-pointer"
        @click.prevent="goToSection('home')"
      />
    </div>
    <div class="flex items-center justify-center gap-10 z-50">
      <a @click.prevent="goToSection('home')" class="link"> Home </a>
      <a @click.prevent="goToSection('services')" class="link"> Serviços </a>
      <a @click.prevent="goToSection('benefits')" class="link"> Benefícios </a>
      <a @click.prevent="goToSection('reviews')" class="link"> Avaliações </a>
    </div>
    <div>
      <Button
        text="Iniciar Sessão"
        bgColor="bg-red-main"
        :onClick="goToLogin"
        v-if="!token"
      />
      <UserDropdown :img="userImage" :username="userName" :role="role" v-else/>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import api from '../service/api';
import UserDropdown from '@/components/UserDropdown.vue';

export default {
  components: {
    Button,
    UserDropdown
  },
  data(){
    return{
      token: localStorage.getItem('token'),
      id: localStorage.getItem('id'),
      img: '',
      userName: '',
      role: '',
    }
  },
  methods: {
    goToSection(sectionId) {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/', hash: `#${sectionId}` });
      } else {
        this.scrollToSection(sectionId);
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    goToLogin() {
      this.$router.push('/login');
    },
    async getUser() {

      if(this.id){
        try {
          const response = await api.get(`user/${this.id}`)
          
          this.userName = response.data.username;
          this.img = response.data.foto
          this.role = response.data.cargo

         
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  mounted() {
    this.getUser()
  }
};
</script>

<style lang="scss" scoped>
.link {
  @apply text-white-main text-[16px] font-medium no-underline transition-colors duration-300 ease-in-out cursor-pointer;

  &:hover {
    @apply text-red-main;
  }

  &.active {
    @apply text-red-main;
  }
}

.background-image {
  position: absolute;
  top: -10px;
  left: -60vw;
  width: 100%;
  height: 100px;
  background-image: url("../assets/img/efects/pingo.png");
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 0;
}
</style>
