<template>
  <section class="h-[120vh] flex gap-10 p-[90px] px-[200px] bg-black-main">
    <div class="flex flex-col gap-20">
      <div class="flex items-center justify-center gap-10">
        <BoostCardLolMd5 @updateData="md5Data" />
      </div>
      <div class="flex flex-col justify-center items-start gap-10 w-[800px]">
        <div class="w-[800px] h-[0px] border border-slate-400"></div>

        <h1 class="text-white font-medium">Como funciona a MD5?</h1>

        <h2 class="text-white text-sm font-medium text-start">
          O serviço de MD5 é voltado para as partidas de promoção (Melhores de
          5), onde o objetivo é garantir o máximo de vitórias possível para
          ajudar o cliente a subir de elo. No modo solo, o booster joga na conta
          do cliente e garante pelo menos 4 vitórias nas 5 partidas. No modo
          duo, o cliente joga ao lado do booster, com a garantia de ao menos 3
          vitórias. Caso o número de vitórias garantidas não seja alcançado,
          oferecemos 2 vitórias extras sem custo adicional, garantindo a melhor
          experiência e resultado possível.
        </h2>
      </div>
    </div>

    <div class="flex flex-col justify-start gap-[30px] w-[500px]">
      <h1 class="text-start text-white text-3xl font-semibold uppercase">
        {{ elo }} {{ modo }} MD5
      </h1>
      <div class="flex justify-start items-center gap-[30px]">
        <h2
          class="w-[90px] h-[40px] p-[10px] text-center bg-white-main rounded-[30px] font-semibold text-base"
        >
          10% OFF
        </h2>
        <h2 class="font-semibold text-red-700 line-through">
          DE: R$ {{ (servicePrice * 1.1).toFixed(2) }}
        </h2>
        <h2 class="font-semibold text-white">POR: R$ {{ servicePrice }}</h2>
      </div>

      <h3 class="text-start text-white text-sm">Personalizar seu pedido</h3>
      <div class="grid grid-rows-3 grid-flow-col gap-4 w-[500px]">
        <Custom text="Lane" price="FREE" />
        <Custom text="Campeões" price="FREE" />
        <Custom text="Stream Discord" price="FREE" />
        <Custom text="Chat Off" price="FREE" />
        <Custom text="Horários" price="+10%" />
        <Custom text="Entrega Rápida" price="+15%" />
      </div>

      <div class="flex flex-col gap-3">
        <h3 class="text-start text-slate-300 text-xs">
          Inicio em até 24 horas
        </h3>
        <h3 class="text-start text-white text-sm">
          Pedindo finalizado em até 30 dias
        </h3>
      </div>

      <Button
        :text="`Contratar (R$ ${servicePrice})`"
        bgColor="bg-red-main"
        @Click="payment()"
        class="w-[500px] h-[60px] p-[10px]"
        :disableButton="servicePrice <= 0"
      />
    </div>
  </section>
  <div class="h-[0.5px] border border-slate-400"></div>
</template>

<script>
import Custom from "../../components/Custom.vue";
import BoostCardLolMd5 from "../../components/BoostCardLolMd5.vue";
import Button from "@/components/Button.vue";
import api from "../../service/api";

export default {
  components: {
    BoostCardLolMd5,
    Custom,
    Button,
  },
  computed: {
    servicePrice() {
      const tabelaPrecos = {
        Unranked: { Solo: 20, Duo: 23 },
        Ferro: { Solo: 14, Duo: 20 },
        Bronze: { Solo: 17, Duo: 23 },
        Prata: { Solo: 19, Duo: 26 },
        Ouro: { Solo: 22, Duo: 29 },
        Platina: { Solo: 31, Duo: 36 },
        Esmeralda: { Solo: 39, Duo: 56 },
        Diamante: { Solo: 41, Duo: 74 },
        Mestre: { Solo: 100, Duo: 150 },
      };

      if (tabelaPrecos[this.elo] && tabelaPrecos[this.elo][this.modo]) {
        return tabelaPrecos[this.elo][this.modo].toFixed(2);
      }
    },
  },
  data() {
    return {
      elo: "",
      modo: "",
      userName: "",
      email: "",
    };
  },
  async mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);
          this.userName = response.data.username;
          this.email = response.data.email;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async payment() {
      try {
        if (!this.id) {
          localStorage.setItem('redirectTo', window.location.href); 
          window.location.href = "/login";
          return;
        }
        
        const response = await api.post("/mercadopago/payment", {
          jogo: "League of legends",
          price: parseFloat(this.servicePrice),
          description: `${this.elo} ${this.modo} MD5`,
          payer: {
            email: this.email,
            name: this.userName,
          },
        });

        window.location.href = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    md5Data(elo, modo) {
      this.elo = elo;
      this.modo = modo;
    },
  },
};
</script>
