<template>
  <section class="h-[120vh] flex gap-10 p-[90px] px-[200px] bg-black-main">
    <div class="flex flex-col gap-20">
      <div class="flex items-center justify-center gap-10">
        <BoostCardClash @updateData="tierData" />
      </div>
      <div class="flex flex-col justify-center items-start gap-10 w-[800px]">
        <div class="w-[800px] h-[0px] border border-slate-400"></div>

        <h1 class="text-white font-medium">Como funciona a MD5?</h1>

        <h2 class="text-white text-sm font-medium text-start">
          O serviço de Elojob para Clash tem como objetivo garantir a vitória no
          torneio e a conquista do troféu para o cliente. Nesse formato, o
          booster joga na conta do cliente durante o evento Clash, utilizando
          toda sua habilidade para buscar as vitórias necessárias para ganhar o
          troféu. Caso o objetivo não seja alcançado, o cliente recebe o valor
          pago de volta, garantindo segurança e confiança no serviço. É a
          oportunidade ideal para quem deseja ter o troféu do Clash no perfil
          sem se preocupar com a pressão das partidas decisivas.
        </h2>
      </div>
    </div>

    <div class="flex flex-col justify-start gap-[30px] w-[500px]">
      <h1 class="text-start text-white text-3xl font-semibold uppercase">
        {{ tier }} CLASH
      </h1>
      <div class="flex justify-start items-center gap-[30px]">
        <h2
          class="w-[90px] h-[40px] p-[10px] text-center bg-white-main rounded-[30px] font-semibold text-base"
        >
          10% OFF
        </h2>
        <h2 class="font-semibold text-red-700 line-through">DE: R$ 44,00</h2>
        <h2 class="font-semibold text-white">POR: R$ 40,00</h2>
      </div>

      <h3 class="text-start text-white text-sm">Personalizar seu pedido</h3>
      <div class="grid grid-rows-3 grid-flow-col gap-4 w-[500px]">
        <Custom text="Lane" price="FREE" />
        <Custom text="Campeões" price="FREE" />
        <Custom text="A partir de 4 aulas" price="10% off" class="w-[205%]" />
        <Custom text="Horários" price="+10%" />
        <Custom text="Entrega Rápida" price="+15%" />
      </div>

      <div class="flex flex-col gap-3">
        <h3 class="text-start text-slate-300 text-xs">
          Inicio em até 24 horas
        </h3>
        <h3 class="text-start text-white text-sm">
          Pedindo finalizado em até 30 dias
        </h3>
      </div>

      <Button
        text="Contrar (R$ 40,00)"
        bgColor="bg-red-main"
        @click="payment()"
        class="w-[500px] h-[60px] p-[10px]"
      />
    </div>
  </section>
  <div class="h-[0.5px] border border-slate-400"></div>
</template>

<script>
import BoostCardClash from "../../components/BoostCardClash.vue";
import Button from "@/components/Button.vue";
import Custom from "../../components/Custom.vue";
import api from "../../service/api";

export default {
  components: {
    BoostCardClash,
    Button,
    Custom,
  },
  data() {
    return {
      tier: "TIER IV",
      userName: "",
      email: "",
    };
  },
  async mounted() {
    this.getUser();
  },
  methods: {
    async payment() {
      try {
        if (!this.id) {
          localStorage.setItem('redirectTo', window.location.href); 
          window.location.href = "/login";
          return;
        }
        
        const response = await api.post("/mercadopago/payment", {
          jogo: "League of legends",
          price: parseFloat(40),
          description: `${this.tier} CLASH`,
          payer: {
            email: this.email,
            name: this.userName,
          },
        });

        window.location.href = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);
          this.userName = response.data.username;
          this.email = response.data.email;
        } catch (error) {
          console.error(error);
        }
      }
    },
    tierData(tier) {
      this.tier = tier;
    },
  },
};
</script>
