<template>
  <section
    class="h-min-[91vh] h-[100%] w-[100vw] flex justify-start flex-col items-center overflow-y-hidden mb-10"
  >
    <div class="flex flex-col items-start pt-20 z-10 gap-3">
      <!-- Profile Section -->
      <div class="flex items-center gap-4 mb-20">
        <div
          class="w-24 h-24 rounded-full flex items-center justify-center bg-black-main"
        >
          <span class="text-white text-4xl font-bold"
            >{{ userName ? userName.charAt(0).toUpperCase() : "" }}
          </span>
        </div>
        <div>
          <h1 class="text-lg font-medium text-left">{{ userName }}</h1>
          <p class="text-black text-sm">
            Membro desde: {{ formatDate(registerDate) }}
          </p>
        </div>
      </div>

      <div
        v-for="pedido in order"
        :key="pedido.id"
        class="bg-card-default rounded-md w-[1554px] justify-between h-[5.83em] bg-black-main flex overflow-y-hidden"
      >
        <div class="flex items-center w-[1554px] max-w-[1554px]">
          <div
            class="bg-[#242333] rounded-l-md justify-center items-center w-[5.83em] h-[5.83em] flex"
          >
            <img
              v-if="pedido.jogo == 'League of legends'"
              src="https://uploads-ssl.webflow.com/61af43245b6a956de3442b2b/624eaed0b5c63f1bc9ca1d54_img-order-league.png"
              loading="lazy"
              alt=""
              class="w-[1.67em] h-[1.67em] align-middle max-w-full inline-block"
            />
            <img
              v-else
              src="../assets/img/valorant-logo.png"
              loading="lazy"
              alt=""
              class="w-[1.67em] h-[1.67em] align-middle max-w-full inline-block"
            />
          </div>
          <div
            class="grid gap-[2.92em] grid-cols-[0.75fr_1.5fr_1.25fr_3.25fr] auto-cols-fr ml-[2.92em]"
          >
            <div>
              <p class="text-[#8a879c] text-sm">Pedido</p>
              <p class="text-white text-base">#{{ pedido.id }}</p>
            </div>
            <!-- Serviço -->
            <div class="w-[250px]">
              <p class="text-[#8a879c] text-sm">Serviço</p>
              <p class="text-white text-base">{{ pedido.pedido }}</p>
            </div>
            <!-- Contratação -->
            <div>
              <p class="text-[#8a879c] text-sm">Contratação</p>
              <p class="text-white text-base">
                {{ formatDate(pedido.data_criacao) }}
              </p>
            </div>
            <!-- Status -->
            <div>
              <p class="text-[#8a879c] text-sm">Status</p>
              <p
                :class="statusClass(pedido.status)"
                class="text-base font-bold"
              >
                {{ statusText(pedido.status) }}
              </p>
            </div>
          </div>
          <div
            class="bg-[#389d85] rounded-l-md justify-center items-center w-[10.83em] h-[5.83em] flex cursor-pointer"
            @click="openModal(pedido)"
          >
            <p class="text-white text-base">Editar Conta</p>
          </div>
        </div>
      </div>

      <contaModal
        v-if="isModalOpen"
        :initialLogin="currentAccount"
        :initialSenha="currentPassword"
        :initialNick="currentNick"
        :id="currentId"
        @close="closeModal"
        @save="saveChanges"
      />
    </div>
  </section>
</template>

<script>
import api from "../service/api";
import contaModal from "../components/contaModal.vue";

export default {
  name: "Profile",
  components: {
    contaModal,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      img: "",
      userName: "",
      currentName: "",
      registerDate: "",
      status: "reprovado",
      collectionStatus: null,
      paymentId: null,
      paymentType: null,
      merchantOrderId: null,
      price: null,
      description: null,
      jogo: null,
      order: {},
      isModalOpen: false,
      currentAccount: "",
      currentPassword: "",
      currentNick: "",
      currentId: "",
    };
  },
  methods: {
    openModal(pedido) {
      this.currentAccount = pedido.conta;
      this.currentPassword = pedido.senha;
      this.currentNick = pedido.nick;
      this.currentId = pedido._id;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async saveChanges(data) {
      await api.put(`order/${data.id}`, {
        nick: data.nick,
        conta: data.login,
        senha: data.senha,
      });

      this.closeModal();
      this.getOrder();
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    statusText(status) {
      switch (status) {
        case "pending":
          return "Pagamento Pendente";
        case "reprovado":
          return "Pagamento Reprovado";
        case "approved":
          return "Pagamento Aprovado";
        case "andamento":
          return "Serviço em andamento";
        case "concluido":
          return "Serviço Concluido";
        default:
          return "Pagamento Reprovado";
      }
    },
    statusClass(status) {
      switch (status) {
        case null:
          return "text-red-500";
        case "pending":
          return "text-orange-500";
        case "reprovado":
          return "text-red-500";
        case "approved":
          return "text-green-500";
        case "entregue":
          return "text-blue-500";
        case "andamento":
          return "text-yellow-500";
        case "concluido":
          return "text-blue-500";
        default:
          return "text-red-500";
      }
    },
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);

          this.currentName = response.data.username;
          this.userName = response.data.username;
          this.img = response.data.foto;
          this.registerDate = response.data.data_registro;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async postOrder() {
      try {
        const response = await api.post("/order/create", {
          usuario: this.id,
          conta: "",
          senha: "",
          valor: this.price,
          pedido: this.description,
          status: this.status,
          jogo: this.jogo,
          booster: "Booster A",
          opcoes: "Opção 1, Opção 2",
          paymentType: this.paymentType,
        });

        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    async getOrder() {
      try {
        const response = await api.get(`/order/${this.id}`);

        this.order = response.data;
        console.log(this.order);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.getUser();

    const {
      collection_status,
      payment_id,
      status,
      payment_type,
      merchant_order_id,
      price,
      description,
      jogo,
    } = this.$route.query;

    this.collectionStatus = collection_status || "N/A";
    this.paymentId = payment_id || "N/A";
    this.status = status || "N/A";
    this.paymentType = payment_type || "N/A";
    this.merchantOrderId = merchant_order_id || "N/A";
    this.price = price ? parseFloat(price).toFixed(2) : "N/A";
    this.description = description ? decodeURIComponent(description) : "N/A";
    this.jogo = jogo ? decodeURIComponent(jogo) : "N/A";

    if (this.paymentId !== "N/A" && this.status !== "N/A") {
      await this.postOrder().then(() => {
        this.$router.push("/pedido");
      });
    }

    await this.getOrder();
  },
  computed: {},
};
</script>

<style scoped>
input::placeholder {
  color: #888;
}
</style>
