<template>
  <section class="flex flex-col h-[90vh] items-center justify-center" id="reviews">
    <div>
      <h1>⭐⭐⭐⭐⭐</h1>
      <h1>Confira nossas avaliações</h1>
      <h2>Nota {{ reviewMedia }} de 5 em {{ reviews.length }} avaliações</h2>
    </div>
    <div class="w-[73vw]">
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide  v-for="(review, index) in reviews":key="index">
          <ReviewCard
            :titleService="review.title"
            :descService="review.description"
            :orderNumber="review.order"
            :reviewNumber="review.reviewNumber"
          />
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>

  </section>
</template>

<script>
import ReviewCard from "@/components/ReviewCard.vue";
import testeUserimg from "../assets/img/transferir.jpeg";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    ReviewCard,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      reviewMedia: 4.4,
      reviews: [
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
        {
          userImg: testeUserimg,
          title: "Elo Boost - Diamante 4 ao Diamante 3",
          description:
            "Leona errava muito, porem conseguiu terminar, sorte que estava duo com uma otima Zeri!!",
          reviewNumber: 5,
          order: 1,
          time: 1000,
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1200: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1500: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>


<style scoped>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__prev {
  color: white !important;
}
</style>
