<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
    <div class="bg-white p-4 rounded-lg w-[80vw] max-w-[800px]">
      <h2 class="text-lg font-semibold mb-4">Editar Pedido</h2>
      <form @submit.prevent="saveOrder">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div class="mb-2">
            <label class="block text-sm font-medium">Nick:</label>
            <input v-model="currentOrder.nick" class="border p-2 w-full text-left" type="text" />
          </div>
          <div class="mb-2">
            <label class="block text-sm font-medium">User:</label>
            <input v-model="currentOrder.conta" class="border p-2 w-full text-left" type="text" />
          </div>
          <div class="mb-2">
            <label class="block text-sm font-medium">Senha:</label>
            <input v-model="currentOrder.senha" class="border p-2 w-full text-left" type="text" />
          </div>
          <div class="mb-2">
            <label class="block text-sm font-medium">Booster:</label>
            <select v-model="currentOrder.booster" class="border p-2 w-full text-left">
              <option value="Milt">Milt</option>
              <option value="Iago">Iago</option>
              <option value="Felipe">Felipe</option>
              <option value="Rocha">Rocha</option>
            </select>
          </div>
          <div class="mb-2">
            <label class="block text-sm font-medium">Status:</label>
            <select v-model="currentOrder.status" class="border p-2 w-full text-left">
              <option value="null">Desconhecido</option>
              <option value="pending">Pendente</option>
              <option value="concluido">Concluído</option>
              <option value="approved">Aprovado</option>
              <option value="andamento">Em andamento</option>
              <option value="concluido">Concluído</option>
            </select>
          </div>
        </div>
        <div class="flex justify-end">
          <button type="button" @click="closeModal()" class="bg-gray-300 text-white px-4 py-2 rounded mr-2">
            Cancelar
          </button>
          <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    currentOrder: {
      type: Object,
      required: true
    }
  },
  emits: ['update:visible', 'saveOrder'],
  methods: {
    closeModal() {
      console.log('a')
      this.$emit('update:visible', false);
    },
    saveOrder() {
      this.$emit('saveOrder');
    },
    getStatusText(status) {
      const statusMapping = {
        pending: "Pendente",
        approved: "Aprovado",
        rejected: "Rejeitado",
        null: "Desconhecido",
        andamento: "Em andamento",
        concluido: "Concluído",
      };
      return statusMapping[status] || "Desconhecido";
    }
  }
}
</script>
