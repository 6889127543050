<template>
  <div class="relative flex items-center">
    <input
      :type="isPasswordVisible ? 'text' : 'password'"
      :placeholder="placeholder"
      class="bg-black-main border-b border-white-main pr-10 text-white text-base w-[350px] h-[75px] focus:outline-none"
      v-model="inputValue"
      @input="updateValue"
    />
    <img
      :src="
        isPasswordVisible
          ? require('@/assets/svg/noview_svg.svg')
          : require('@/assets/svg/noview_svg.svg')
      "
      alt="toggle password visibility"
      class="absolute right-2 w-6 h-6 cursor-pointer"
      @click="togglePasswordVisibility"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
      inputValue: "",
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    updateValue() {
      this.$emit("update-input", this.inputValue);
    },
  },
};
</script>
