<template>
  <div
    class="bg-white w-[240px] h-[50px] p-2.5 rounded-[5px] justify-between items-center flex"
  >
    <h1 class="text-black text-lg font-semibold">{{ text }}</h1>
    <h1 class="text-red-500 text-sm font-semibold">{{ price }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
};
</script>
