<template>
  <body>
    <HeaderApp class="z-10"/>
    <router-view/>
  </body>
  <Footer v-if="!$route.meta.hideFooter" />
</template>

<script>
import Footer from './components/Footer.vue';
import HeaderApp from './components/HeaderApp.vue';

export default {
  name: 'App',  
  components: {
    HeaderApp,
    Footer
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --vc-nav-color: #EA234F;
  --vc-nav-color-hover: #d4778c;
  overflow-x: hidden;
  overflow-y: hidden;
}

body{
  @apply bg-white-main text-center;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}
</style>
