<template>
  <section class="w-[100vw] flex items-center flex-col justify-center h-[100vh]" id="services">
    <div
      class="w-[427px] h-[422px] absolute right-[-184px] top-[1004px] bg-x-image bg-cover bg-no-repeat"
    ></div>
    <div
      class="w-[200px] h-[241.863px] absolute left-[200px] top-[1100px] bg-x-image bg-cover bg-no-repeat rotate-[-111deg]"
    ></div>
    <div
      class="w-[1330px] h-[1507.827px] absolute right-[-700px] bottom-[-2000px] bg-pingo-image bg-cover bg-no-repeat"
    ></div>
    <div class="w-[73vw] flex justify-end pr-8">
      <GameSelect @game-selected="handleGameSelected"/>
    </div>

    <div class="w-[73vw] mt-5">
      <Carousel v-bind="settings" :breakpoints="breakpoints" v-if="selectedGame === 'leagueoflegends'">
        <Slide v-for="(service, index) in servicesLol" :key="index">
          <ServiceCard
            :tittleText="service.title"
            :descriptionText="service.description"
            :onClick="goToElojob"
            :imgText="service.img"
            :route="service.route"
          />
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>

      <Carousel v-bind="settings" :breakpoints="breakpoints" v-if="selectedGame === 'valorant'">
        <Slide v-for="(service, index) in servicesValorant" :key="index">
          <ServiceCard
            :tittleText="service.title"
            :descriptionText="service.description"
            :onClick="goToElojob"
            :imgText="service.img"
            :route="service.route"
          />
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import GameSelect from "../components/GameSelect.vue"
import ServiceCard from "../components/ServiceCard.vue";
import elojobImage from "../assets/img/serviceText/ELOJOB.png";
import clashImage from "../assets/img/serviceText/CLASH.png";
import duobostImage from "../assets/img/serviceText/DUOBOOST.png";
import md5Image from "../assets/img/serviceText/MD5.png";
import coachImage from "../assets/img/serviceText/COACH.png";
import flexImage from "../assets/img/serviceText/FLEX.png"
import premierIamge from "../assets/img/serviceText/PREMIER.png"
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    ServiceCard,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    GameSelect
  },
  data() {
    return {
      servicesLol: [
        {
          title: "Nós jogamos pra você",
          description:
            "É uma ótima escolha para quem quer agilidade no serviço, iremos jogar na sua conta até atingir a divisão solicitada, você pode customizar lane/campeões e muito mais!",
          img: elojobImage,
          route: '/leagueoflegends/eloboost'
        },
        {
          title: "Jogue DUO com um Desafiante",
          description:
            "Você quer o máximo de segurança para você e sua conta? Jogue duo com um Challenger e aprenda as melhores técnicas para subir de ELO!",
          img: duobostImage,
          route: '/leagueoflegends/duoboost'
        },
        {
          title: "Suba de elo na FLEX",
          description:
            "É uma ótima escolha para quem quer agilidade no serviço, iremos jogar na sua conta até atingir a divisão solicitada na fila FLEX, você pode customizar lane/campeões e muito mais!",
          img: flexImage,
          route: '/leagueoflegends/flex'
        },
        {
          title: "Garanta sua MD5",
          description:
            "Nós iremos garantir 80% de vitórias na sua MD5, os melhores Boosters são selecionados para fazer esse tipo de serviço e garantir as vitórias!",
          img: md5Image,
          route: '/leagueoflegends/md5'
        },
        {
          title: "Levante seu Troféu",
          description:
            "Adquira já o troféu do Clash e mostre sua força na arena. Não perca a chance de ter esse símbolo de vitória no seu perfil. Jogue e conquiste!",
          img: clashImage,
          route: '/leagueoflegends/clash'
        },
        {
          title: "Aprenda com os melhores",
          description:
            "Aprenda com os melhores e domine a rota. Sessões de coaching personalizadas para melhorar suas mecânicas, visão de jogo e tomada de decisão. Chegue ao próximo nível em League of Legends",
          img: coachImage,
          route: '/leagueoflegends/coach'
        },
      ],
      servicesValorant: [
        {
          title: "Nós jogamos pra você",
          description:
            "É uma ótima escolha para quem quer agilidade no serviço, iremos jogar na sua conta até atingir a divisão solicitada, você pode customizar os agentes e muito mais!",
          img: elojobImage,
          route: '/valorant/eloboost'
        },
        {
          title: "Jogue DUO com um Radiante",
          description:
            "Você quer o máximo de segurança para você e sua conta? Jogue duo com um Radiante e aprenda as melhores técnicas para subir de ELO!",
          img: duobostImage,
          route: '/valorant/duoboost'
        },
        {
          title: "Garanta sua MD5",
          description:
            "Nós iremos garantir 80% de vitórias na sua MD5, os melhores Boosters são selecionados para fazer esse tipo de serviço e garantir as vitórias!",
          img: md5Image,
          route: '/valorant/md5'
        },
/*         {
          title: "Levante seu Troféu",
          description:
            "Adquira já o troféu do Premier e mostre sua força na arena. Não perca a chance de ter esse símbolo de vitória no seu perfil. Jogue e conquiste!",
          img: premierIamge,
          route: '/valorant/premier'
        }, */
        {
          title: "Aprenda com os melhores",
          description:
            "Aprenda com os melhores e melhore a mira. Sessões de coaching personalizadas para melhorar suas mecânicas, visão de jogo e tomada de decisão. Chegue ao próximo nível no Valorant",
          img: coachImage,
          route: '/valorant/coach'
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1200: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1500: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      selectedGame: 'leagueoflegends',
    };
  },
  methods: {
    goToElojob() {
      console.log("elojob");
    },
    handleGameSelected(selectedGame) {
      this.selectedGame = selectedGame; 
      console.log(selectedGame)
    },
  },
};
</script>

<style scoped>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__prev {
  color: white !important;
}
</style>
