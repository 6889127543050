<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal-content bg-black-main p-6 rounded-lg w-[300px]">
        <button class="close-button bg-white-main text-white  p-1 rounded-full" @click="closeModal">✖</button>
        <h2 class="text-lg font-semibold text-white text-start mb-5">Editar Conta</h2>
        <div class="form-group mb-4">
          <label for="nick" class="block text-sm text-start font-medium text-white-main">Nick:</label>
          <input
            type="text"
            id="nick"
            v-model="nick"
            placeholder="Digite seu Nick"
            class="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>
        <div class="form-group mb-4">
          <label for="login" class="block text-sm text-start font-medium text-white-main">Login:</label>
          <input
            type="text"
            id="login"
            v-model="login"
            placeholder="Digite seu login"
            class="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>
        <div class="form-group mb-4">
          <label for="senha" class="block text-sm text-start font-medium text-white-main">Senha:</label>
          <input
            type="text"
            id="senha"
            v-model="senha"
            placeholder="Digite sua senha"
            class="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>
        <div class="modal-actions flex justify-between mt-4">
          <button @click="save" class="bg-red-main text-white px-4 py-2 rounded">Salvar</button>
          <button @click="cancel" class="bg-white-main text-black px-4 py-2 rounded">Cancelar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Modal",
    props: {
      id: {
        type: String,
        required: true
      },
      initialLogin: {
        type: String,
        required: true,
      },
      initialSenha: {
        type: String,
        required: true,
      },
      initialNick: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        login: this.initialLogin,
        senha: this.initialSenha,
        nick: this.initialNick
      };
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      save() {
        this.$emit("save", { login: this.login, senha: this.senha, nick: this.nick, id: this.id });
        this.closeModal();
      },
      cancel() {
        this.closeModal();
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  </style>
  