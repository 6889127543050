<template>
  <section
    class="w-[100vw] h-[100vh] bg-red-main flex justify-center items-center"
  >
  <div
      class="z-0 text-white-main w-[90%] h-[100%] absolute bg-rabisco-white-image bg-cover bg-no-repeat"
    ></div>
    <div
      class="z-10 w-[500px] h-[650px] gap-20 bg-black-main rounded-3xl flex justify-center items-center flex-col"
    >
      <div>
        <img
          src="../assets/img/logo-main-job.png"
          alt="Dinasty"
          class="h-20 w-60"
        />
        <h1 class="text-white-main">Acesse sua conta</h1>
      </div>

      <div class="gap-4 flex flex-col">
        <ButtonAccount placeholder="E-mail" iconSrc="person_svg.svg" @update-input="emailChange"/>
        <ButtonPassword placeholder="Senha" @update-input="senhaChange"/>
        <h1 class="text-red-500">{{errorSenha}}</h1>
      </div>
      <div>
        <Button
          bgColor="bg-white-main"
          textColor="text-black-main"
          text="ENTRAR"
          class="w-[300px]"
          @click="login()"
        />
        <h1 class="text-white-main text-sm mt-2">
          Ainda não tem uma conta? 
          <router-link to="/register" class="underline hover:text-red-main">
            Registre-se
          </router-link>
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonAccount from "@/components/ButtonAccount.vue";
import ButtonPassword from "@/components/ButtonPassword.vue"
import Button from "@/components/Button.vue";
import api from '../service/api';

export default {
  components: {
    ButtonAccount,
    Button,
    ButtonPassword
  },
  data(){
    return{
      errorSenha: '',
      email: '',
      password: ''
    }
  },
  methods:{
    async login() {
      try {
        const response = await api.post('/auth/login', {
          email: this.email,
          password: this.password
        });
        
    
        const token = response.data.token;
        const id = response.data.id;

        localStorage.setItem('token', token);
        localStorage.setItem('id', id);

        const redirectTo = localStorage.getItem('redirectTo');

      if (redirectTo) {
        localStorage.removeItem('redirectTo');
        window.location.href = redirectTo;
      } else {
        window.location.href = "/";
      }
      } catch (error) {
        this.errorSenha = error.response.data.message;
        console.error(error);
      }
    },
    emailChange(input){
      this.email = input
    },
    senhaChange(input){
      this.password = input
    }
  }
};
</script>

<style></style>
