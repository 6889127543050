<template>
  <button
    :class="[
      'w-[180px]',
      'h-[40px]',
      'flex',
      'items-center',
      'justify-center',
      'font-medium',
      'text-[16px]',
      'rounded-[12px]',
      textColor,
      bgColor,
      disableButton ? 'opacity-50 cursor-not-allowed' : ''
    ]"
    :disabled="disableButton"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
      default: "text-white-main"
    },
    onClick: {
      type: Function,
      required: true,
    },
    disableButton:{
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleClick() {
      this.onClick();
    },
  },
};
</script>
