<template>
  <section class="h-[120vh] flex gap-10 p-[90px] px-[200px] bg-black-main">
    <div class="flex flex-col gap-20">
      <div class="flex items-center justify-center gap-10">
        <BoostCardCoach @updateData="coachTier" />
      </div>
      <div class="flex flex-col justify-center items-start gap-10 w-[800px]">
        <div class="w-[800px] h-[0px] border border-slate-400"></div>

        <h1 class="text-white font-medium">Como funciona a MD5?</h1>

        <h2 class="text-white text-sm font-medium text-start">
          O serviço de coaching oferece ao cliente a oportunidade de aprender e
          aprimorar suas habilidades diretamente com um jogador experiente
          (coach). Durante as sessões, o coach analisa o desempenho do cliente,
          identificando pontos fortes e áreas de melhoria, além de oferecer
          dicas personalizadas e estratégias específicas para subir de elo. As
          sessões podem ser focadas em mecânicas, tomada de decisões, controle
          de mapa, e muito mais. O coaching é uma excelente escolha para quem
          deseja evoluir no jogo de forma consistente e autônoma, contando com a
          orientação de um profissional para alcançar seus objetivos.
        </h2>
      </div>
    </div>

    <div class="flex flex-col justify-start gap-[30px] w-[500px]">
      <h1 class="text-start text-white text-3xl font-semibold uppercase">
        {{ tierCoach }} COACH
      </h1>
      <div class="flex justify-start items-center gap-[30px]">
        <h2
          class="w-[100px] h-[40px] p-[10px] text-center bg-white-main rounded-[30px] font-semibold text-base"
          v-if="quantity < 4"
        >
          10% OFF
        </h2>
        <h2
          class="w-[100px] h-[40px] p-[10px] text-center bg-white-main rounded-[30px] font-semibold text-base"
          v-else
        >
          20% OFF
        </h2>
        <h2 class="font-semibold text-red-700 line-through" v-if="quantity < 4">
          DE: R$ {{ (servicePrince * 1.1).toFixed(2) }}
        </h2>
        <h2 class="font-semibold text-red-700 line-through" v-else>
          DE: R$ {{ (servicePrince * 1.2).toFixed(2) }}
        </h2>
        <h2 class="font-semibold text-white">POR: R$ {{ servicePrince }}</h2>
      </div>

      <h3 class="text-start text-white text-sm">Personalizar seu pedido</h3>
      <div class="grid grid-rows-3 grid-flow-col gap-4 w-[500px]">
        <Custom text="Lane" price="FREE" />
        <Custom text="Campeões" price="FREE" />
        <Custom text="A partir de 4 aulas" price="10% off" class="w-[206%]" />
        <Custom text="Horários" price="+10%" />
        <Custom text="Entrega Rápida" price="+15%" />
      </div>

      <div class="flex items-center gap-4">
        <button
          @click="decreaseQuantity"
          class="text-white bg-red-main rounded-full w-8 h-8 flex items-center justify-center"
        >
          -
        </button>
        <span class="text-white text-xl">{{ quantity }}</span>
        <button
          @click="increaseQuantity"
          class="text-white bg-red-main rounded-full w-8 h-8 flex items-center justify-center"
        >
          +
        </button>
      </div>

      <div class="flex flex-col gap-3">
        <h3 class="text-start text-slate-300 text-xs">
          Inicio em até 24 horas
        </h3>
        <h3 class="text-start text-white text-sm">
          Pedindo finalizado em até 30 dias
        </h3>
      </div>

      <Button
        :text="`Contratar (R$ ${servicePrince})`"
        bgColor="bg-red-main"
        @click="payment()"
        class="w-[500px] h-[60px] p-[10px]"
        :disableButton="servicePrince <= 0"
      />
    </div>
  </section>
  <div class="h-[0.5px] border border-slate-400"></div>
</template>

<script>
import BoostCardCoach from "../../components/BoostCardValCoach.vue";
import Button from "@/components/Button.vue";
import Custom from "../../components/Custom.vue";
import api from "../../service/api";

export default {
  components: {
    BoostCardCoach,
    Button,
    Custom,
  },
  computed: {
    servicePrince() {
      const prices = {
        Básico: 25,
        Intermediario: 32,
        Avançado: 40,
      };

      const basePrice = prices[this.tierCoach] || 0;
      const total = basePrice * this.quantity;

      return (this.quantity > 3 ? total * 0.9 : total).toFixed(2);
    },
  },
  data() {
    return {
      tierCoach: "",
      quantity: 1,
      userName: "",
      email: "",
    };
  },
  methods: {
    async payment() {
      try {
        if (!this.id) {
          localStorage.setItem('redirectTo', window.location.href); 
          window.location.href = "/login";
          return;
        }
        
        const response = await api.post("/mercadopago/payment", {
          jogo: "Valorant",
          price: parseFloat(this.servicePrince),
          description: `${this.quantity}x ${this.tierCoach} COACH`,
          payer: {
            email: this.email,
            name: this.userName,
          },
        });
        
       window.location.href = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUser() {
      if (this.id) {
        try {
          const response = await api.get(`user/${this.id}`);
          this.userName = response.data.username;
          this.email = response.data.email;
        } catch (error) {
          console.error(error);
        }
      }
    },
    coachTier(tier) {
      this.tierCoach = tier;
    },
    increaseQuantity() {
      if (this.quantity < 10) {
        this.quantity++;
      }
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
  },
};
</script>
