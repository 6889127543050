<template>
  <div class="relative flex items-center">
    <input
      type="text"
      :placeholder="placeholder"
      class="bg-black-main border-b border-white-main pr-10 text-white text-base w-[350px] h-[75px] focus:outline-none"
      v-model="inputValue"
      @input="updateValue"
    />
    <img
      :src="require(`@/assets/svg/${iconSrc}`)"
      alt="icon"
      class="absolute right-2 w-6 h-6 pointer-events-none"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    updateValue() {
      this.$emit("update-input", this.inputValue);
    },
  },
};
</script>
