import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LogginView from '../views/LogginView.vue'
import RegisterView from '../views/RegisterView.vue'
import AdminView from '../views/AdminView.vue'
import ProfileView from '../views/ProfileView.vue'
import ErroView from '../views/ErroView.vue'
import SucessoView from '../views/SucessoView.vue'
import PendingView from '../views/PendingView.vue'

import ElojobView from '../views/league/ElojobView.vue'
import DuoboostView from '../views/league/DuoboostView.vue'
import Md5View from '../views/league/Md5View.vue'
import CoachView from '../views/league/CoachView.vue'
import FlexView from '../views/league/FlexView.vue'
import ClashView from '../views/league/ClashView.vue'

import ElojobValorantView from '../views/valorant/ElojobView.vue'
import DuoboostValorantView from '../views/valorant/DuoboostView.vue'
import Md5ValorantView from '../views/valorant/Md5View.vue'
import CoachValorantView from '../views/valorant/CoachView.vue'
import PremierValorantView from '../views/valorant/PremierView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LogginView,
    meta: { hideFooter: true }
  },
  {
    path: '/pedido',
    name: 'pedido',
    component: SucessoView,
    meta: { hideFooter: true }
  },
  {
    path: '/pending',
    name: 'pending',
    component: PendingView,
    meta: { hideFooter: true }
  },
  {
    path: '/erro',
    name: 'erro',
    component: ErroView,
    meta: { hideFooter: true }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { hideFooter: true }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: { hideFooter: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: { hideFooter: true }
  },
  {
    path: '/leagueoflegends',
    name: 'leagueoflegends',
    children: [
      {
        path: 'eloboost',
        name: 'leagueoflegends-eloboost',
        component: ElojobView
      },
      {
        path: 'duoboost',
        name: 'leagueoflegends-duoboost',
        component: DuoboostView
      },
      {
        path: 'md5',
        name: 'leagueoflegends-md5',
        component: Md5View
      },
      {
        path: 'coach',
        name: 'leagueoflegends-coach',
        component: CoachView
      },
      {
        path: 'clash',
        name: 'leagueoflegends-clash',
        component: ClashView
      },
      {
        path: 'flex',
        name: 'leagueoflegends-flex',
        component: FlexView
      }
    ]
  },
  {
    path: '/valorant',
    name: 'valorant',
    children: [
      {
        path: 'eloboost',
        name: 'valorant-eloboost',
        component: ElojobValorantView
      },
      {
        path: 'duoboost',
        name: 'valorant-duoboost',
        component: DuoboostValorantView
      },
      {
        path: 'md5',
        name: 'valorant-md5',
        component: Md5ValorantView
      },
      {
        path: 'coach',
        name: 'valorant-coach',
        component: CoachValorantView
      },
/*       {
        path: 'premier',
        name: 'valorant-premier',
        component: PremierValorantView
      } */
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});


router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if ((to.name === 'login' || to.name === 'register') && token) {
    next({ name: 'home' });
  } else if(to.name === 'profile' && !token){
    next({name: 'home'});
  } else{
    next()
  }
});

export default router;