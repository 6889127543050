<template>
    <div
      class="gap-[30px] w-[380px] h-[590px] rounded-[30px] flex flex-col items-center justify-center"
      :class="selectEloBg"
    >
      <img class="w-[230px] h-[220px]" :src="selectImg" />

      <h1 class="text-white text-[50px] font-bold">
        {{ selectedDesc }}
      </h1>
  
      <div class="w-[100%] items-center justify-center flex flex-col">
        <h1 class="text-white text-base font-semibold">
          Selecione seu tier atual
        </h1>
        <Button
          class="w-[330px] h-[50px]"
          :text="selectedDesc"
          :bgColor="selectButton"
          :onClick="openModal"
        />
      </div>
   
      <div
        v-if="showModal"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div
          class="bg-white w-[60vw] h-[300px] rounded-[20px] p-[20px] flex flex-col items-center justify-between"
        >
          <div class="w-[100%] flex justify-between">
            <h2 class="text-black text-[24px] font-semibold mt-[10px]">
              Escolha seu Tier
            </h2>
            <button
              @click="closeModal"
              class="self-end text-black text-[20px] font-bold"
            >
              X
            </button>
          </div>
  
          <div class="flex gap-[100px]">
            <div
              class="cursor-pointer hover:bg-red-main hover:text-white rounded-[20px] flex flex-col justify-between items-center"
              v-for="(select, index) in elos"
              :key="index"
              @click="selectElo(select)"
            >
              <img class="w-[180px]" :src="select.img" />
              <h1 class="text-center font-semibold text-xl">{{ select.desc }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Button from "./Button.vue";
  import tierI from "../assets/img/eloLeague/tierI.png";
  import tierII from "../assets/img/eloLeague/tierII.png";
  import tierIII from "../assets/img/eloLeague/tierIII.webp";
  import tierIV from "../assets/img/eloLeague/tierIV.webp";

  
  export default {
    components: {
      Button,
    },
    data() {
      return {
        showDropdown: false,
        showModal: false,
        selectedDesc:"Titer IV",
        selectImg: tierIV,
        selectEloBg: 'bg-red-500',
        selectButton: 'bg-red-900',
        elos: [
          {
            desc: "Titer IV",
            img: tierIV,
            bgColor: "bg-red-500",
            buttonColor: "bg-red-900",
          },
          {
            desc: "Tier III",
            img: tierIII,
            bgColor: "bg-ferro-500",
            buttonColor: "bg-ferro-900",
          },
          {
            desc: "Tier II",
            img: tierII,
            bgColor: "bg-bronze-500",
            buttonColor: "bg-bronze-900",
          },
          {
            desc: "Tier I",
            img: tierI,
            bgColor: "bg-ferro-500",
            buttonColor: "bg-ferro-900",
          },
        ],
      };
    },
    computed: {
      divisionNumber() {
        return this.selectedDivision.split(" ")[1];
      },
    },
    methods: {
      goto() {
        console.log("aqui");
      },
      toggleDropdown() {
        this.showDropdown = !this.showDropdown;
      },
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      selectElo(select) {
        this.selectedDesc = select.desc;
        this.selectImg = select.img;
        this.selectEloBg = select.bgColor;
        this.selectButton = select.buttonColor;
        this.showModal = false;
        this.refreshData()
      },
      refreshData() {
      this.$emit("updateData", this.selectedDesc);
    },
    },
  };
  </script>
  
  <style></style>
  