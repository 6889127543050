<template>
  <div
    class="gap-[30px] w-[380px] h-[590px] rounded-[30px] flex flex-col items-center justify-center"
    :class="selectEloBg"
  >
    <img class="w-[100%] h-[220px]" :src="selectImg" />

    <h1 class="text-white text-[50px] font-bold">
      {{ selectedElo }}
    </h1>

    <div class="w-[100%] items-center justify-center flex flex-col">
      <h1 class="text-white text-base font-semibold">
        Selecione seu tier atual
      </h1>
      <Button
        class="w-[330px] h-[50px]"
        :text="selectedElo"
        :bgColor="selectButton"
        :onClick="openModal"
      />
    </div>

    <div
      v-if="showModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div
        class="bg-white w-[80vw] h-[400px] rounded-[20px] p-[20px] flex flex-col items-center justify-between"
      >
        <div class="w-[100%] flex justify-between">
          <h2 class="text-black text-[24px] font-semibold mt-[10px]">
            Escolha seu Tier
          </h2>
          <button
            @click="closeModal"
            class="self-end text-black text-[20px] font-bold"
          >
            X
          </button>
        </div>

        <div class="flex">
          <div
            class="cursor-pointer hover:bg-red-main hover:text-white rounded-[20px] flex flex-col justify-center items-center"
            v-for="(select, index) in elos"
            :key="index"
            @click="selectElo(select)"
          >
            <img class="w-[80%]" :src="select.img" />
            <h1 class="text-center font-semibold text-xl">{{ select.elo }}</h1>
            <h1 class="text-center">{{ select.desc }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import tier1 from "../assets/img/eloValorant/tier1.png";
import tier2 from "../assets/img/eloValorant/tier2.png";
import tier3 from "../assets/img/eloValorant/tier3.png";

export default {
  components: {
    Button,
  },
  data() {
    return {
      showDropdown: false,
      showModal: false,
      selectedElo: "Básico",
      selectedDesc: "Ferro ao prata",
      selectImg: tier1,
      selectEloBg: "bg-slate-500",
      selectButton: "bg-slate-900",
      elos: [
        {
          elo: "Básico",
          desc: "Ferro ao prata",
          img: tier1,
          bgColor: "bg-ferro-500",
          buttonColor: "bg-ferro-900",
        },
        {
          elo: "Intermediario",
          desc: "Gold ao diamante",
          img: tier2,
          bgColor: "bg-bronze-500",
          buttonColor: "bg-bronze-900",
        },
        {
          elo: "Avançado",
          desc: "Ascendente+",
          img: tier3,
          bgColor: "bg-red-500",
          buttonColor: "bg-red-900",
        },
      ],
    };
  },
  computed: {
    divisionNumber() {
      return this.selectedDivision.split(" ")[1];
    },
  },
  methods: {
    goto() {
      console.log("aqui");
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    selectElo(select) {
      this.selectedElo = select.elo;
      this.selectImg = select.img;
      this.selectEloBg = select.bgColor;
      this.selectButton = select.buttonColor;
      this.showModal = false;
      this.refreshData();
    },
    refreshData() {
      this.$emit("updateData", this.selectedElo);
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style></style>
