<template>
  <section
    class="flex flex-col h-[90vh] items-center justify-center gap-[38px]"
    id="benefits"
  >
    <h2 class="text-[16px] font-semibold leading-normal text-red-main">
      Rápido, fácil e Seguro
    </h2>
    <h1
      class="text-center text-[32px] font-bold leading-normal text-black-main"
    >
      Suba de elo com as vantagens do serviço Dinasty
    </h1>
    <div class="grid grid-rows-2 grid-flow-col gap-4 h-[500px]">
      <div
        class="flex flex-col items-start justify-start content-start gap-[15px_10px] flex-wrap w-[314px] h-[229px] p-[22px_10px_10px_10px] bg-red-main rounded-[20px] drop-shadow-md"
      >
        <h1
          class="text-[20px] font-extrabold leading-normal text-white w-[100%] text-centerr"
        >
          Qualidade e Profissionalismo
        </h1>
        <h2 class="text-[12px] font-medium leading-normal text-white">
          Nossa equipe é composta por especialistas dedicados a oferecer o
          melhor serviço possível, assegurando resultados excepcionais
        </h2>
      </div>
      <div
        class="flex flex-col items-start justify-start content-start gap-[15px_10px] flex-wrap w-[314px] h-[189px] p-[22px_10px_10px_10px] bg-red-main mt-[-10px] rounded-[20px] drop-shadow-md"
      >
        <h1
          class="text-[20px] font-extrabold leading-normal text-white w-[100%] text-center"
        >
          Suporte Contínuo
        </h1>
        <h2 class="text-[12px] font-medium leading-normal text-white">
          Estamos sempre disponíveis para oferecer suporte e resolver quaisquer
          dúvidas ou problemas que possam surgir durante o processo.
        </h2>
      </div>
      <div
        class="flex flex-col items-start justify-start content-start gap-[15px_10px] flex-wrap w-[314px] h-[141px] p-[22px_10px_10px_10px] bg-red-main rounded-[20px] drop-shadow-md"
      >
        <h1
          class="text-[20px] font-extrabold leading-normal text-white w-[100%] text-center"
        >
          Prazo Garantido
        </h1>
        <h2 class="text-[12px] font-medium leading-normal text-white">
          Seu pedido é sempre entregue dentro do prazo combinado
        </h2>
      </div>
      <div
        class="flex flex-col items-center justify-start content-start gap-[35px_10px] flex-wrap w-[314px] h-[280px] p-[22px_10px_10px_10px] bg-black-main mt-[-100px] rounded-[20px] drop-shadow-md"
      >
        <h1
          class="text-[20px] font-extrabold leading-normal text-white w-[100%] text-center"
        >
          Disponível 24/7
        </h1>
        <h2 class="text-[12px] font-medium leading-normal text-white">
          Nossos serviços estão disponível 24 horas por dia, todos os dias
        </h2>
        <img
        src="../assets/img/logo-main-job.png"
        class="w-[180px] h-[65px] object-cover"
        alt="Dinasty"
      />
      </div>
      <div
        class="flex flex-col items-center justify-start content-start gap-[35px_10px] flex-wrap w-[314px] h-[437px] p-[22px_10px_10px_10px] bg-black-main rounded-[20px] drop-shadow-md"
      >
        <h1
          class="text-[20px] font-extrabold leading-normal text-white w-[100%] text-center"
        >
          100% de privacidade e segurança
        </h1>
        <h2 class="text-[12px] font-medium leading-normal text-white">
          Utilizamos tecnologia avançada para assegurar que suas informações
          estejam seguras e que a contratação de um serviço permaneça totalmente
          confidencial.
        </h2>
        <img
        src="../assets/img/security.png"
        class="w-[152px] h-[150px] object-cover"
        alt="Seguro"
      />
      </div>
    </div>
  </section>
</template>

